$color-primary: #ffca28;
$color-primary-dark: darken($color-primary, 10);
$color-primary-light: lighten($color-primary, 10);
$color-secondary: #388e3c;
$color-secondary-dark: darken($color-secondary, 10);
$color-secondary-light: lighten($color-secondary, 10);
$color-text: #333333;

$breakpoints: (
  xs: 0px,
  s: 340px,
  sm: 600px,
  md: 960px,
  lg: 1280px,
  xl: 1920px,
);
@import 'scss/mediaqueries';

$spacing: 8px;

@function spacing($factor) {
  @return $factor * $spacing;
}

img {
  width: 100%;
  margin-top: -5px;
}
* {
  box-sizing: border-box;
}
body,
html {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: $color-text;
}
.primary {
  color: $color-primary;
}

.secondary {
  color: $color-secondary;
}

.section {
  min-height: 100vh;
  display: flex;
  align-items: center;
  &.hero {
    position: relative;
    background-size: cover;
    background-position: center;
    color: #fff;
    .filter {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: #000;
      opacity: 0.5;
      z-index: 0;
    }
    .content {
      width: auto;
      padding-top: 30vh;
    }
    .title {
      color: $color-primary;

      img {
        width: 25px;
      }
    }
    .subtitle {
      color: #fff;
    }
    .text {
      font-size: 1.3rem;
    }
  }
  &.partner {
    background: $color-secondary;
    color: white;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;

    a {
      color: white;
      text-decoration: none;
      &:hover {
        color: $color-primary-light;
      }
    }

    .item {
      padding: 0;
      width: 100%;
    }

    img {
      width: 200px;
    }

    .partner-list {
      display: flex;
      width: 100%;
      justify-content: space-evenly;

      span {
        font-style: italic;
      }
    }
    

    ul li{
      display: inline;
      list-style-type:none;
      padding: 0;
      margin: 0;
    }
  }
  &--primary {
    background-color: $color-primary-dark;
    .title {
      color: $color-secondary;
    }
    .subtitle {
      color: #fff;
    }
  }
  &.interlude {
    min-height: auto;
  }
  &--secondary {
    background-color: $color-primary-dark;
    padding: spacing(4);
    .content {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    .item {
      text-align: center;
      padding: spacing(2);
    }
  }

  &--clear {
    margin-bottom: -5px;
  }
}

.spacer {
  height: spacing(4);
}

footer {
  padding: 0;
  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .item {
    text-align: center;
  }
  .impressum {
    font-size: 0.7rem;
    line-height: 1rem;
    padding-bottom: 0;
    h4,
    p {
      margin: 0;
    }
  }
}

.content {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: spacing(2);
  padding-top: spacing(4);
}

.title {
  font-family: 'Open Sans', sans-serif;
  color: $color-secondary-dark;
  font-size: 2rem;
  line-height: 2.2rem;
  margin-bottom: spacing(2);
}
.subtitle {
  color: $color-primary-dark;
  font-size: 1.6rem;
  line-height: 1.8rem;
  margin-bottom: spacing(4);
}
a {
  color: $color-secondary;
}
.text {
  font-size: 1rem;
  line-height: 1.5rem;
  &--wrap {
    display: flex;
    flex-direction: column;
    p {
      padding: spacing(2);

      &:nth-child(even) {
        align-self: flex-end;
      }
    }

    p::first-letter {
      font-size: 1.5rem;
      font-weight: 600;
      color: $color-secondary;
    }
  }
  strong {
    font-weight: 600;
    color: $color-secondary;
  }
  i {
    font-style: italic;
    color: $color-secondary;
  }
  b {
    font-weight: normal;
    color: $color-secondary;
  }
}
.cols {
  display: flex;
  padding-top: spacing(4);
  align-items: flex-start;
  flex-direction: column;

  &.language {
    padding-top: 0;
    flex-direction: row;
    img {
      width: auto;
      margin-right: 5px;
    }
  }

  h4 {
    margin: 0;
    margin-bottom: spacing(1);
    font-weight: normal;
    font-size: 1.2rem;
    margin-bottom: spacing(1);
  }
  .col {
    padding: spacing(2);
    align-items: center;
    align-content: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    table {
      display: table;
      border: 1px solid $color-primary;
      border-collapse: collapse;
      padding: spacing(2);
    }
    p {
      margin: 0;
      padding-top: spacing(2);
    }
  }
}

.button {
  padding: spacing(2) spacing(1);
  background-color: $color-primary;
  text-decoration: none;
  color: $color-text;
  transition: all 0.1s ease-in-out;
  font-size: 0.7rem;

  &:hover {
    background-color: $color-primary-dark;
  }

  &--main {
    z-index: 100;
    position: fixed;
    top: spacing(2);
    right: spacing(2);
    font-size: 1rem;
  }

  &--secondary {
    background-color: $color-secondary;
    color: #fff;
    &:hover {
      background-color: $color-secondary-dark;
    }
  }
}
td, tr, table, tbody {
  width: 100%;
  display: block;
}

td {
  padding: 5px 5px 5px 5px;
  white-space: nowrap;
  &:last-child {
    border: none;
  }
}

.shariff {
  width: 100%;

  ul {
    justify-content: center;

    li {
      min-width: 0 !important;
    }
  }
}

tr {
  border-bottom: 1px solid $color-primary;
  &:last-child {
    border: none;
  }
}

.donate-note {
  text-align: center;
  font-size: 0.5rem;
}

.show {
  opacity: 1;
  transition: opacity 500ms;
}

.hide {
  opacity: 0;
  transition: opacity 350ms;
}

.copy.icon {
  visibility: hidden;
  cursor: pointer;
  width: 8px;
  height: 11px;
  border: solid 1px $color-primary-dark;
  border-radius: 1px;
  position: absolute;
  margin-left: 5px;
  margin-top: 15px;
  &:before {
    content: "";
    display: block;
    position: absolute;
    left: -3px;
    top: -3px;
    width: 8px;
    height: 11px;
    border-top: solid 1px $color-primary-dark;
    border-left: solid 1px $color-primary-dark;
    border-radius: 1px 0 0 0;
  }
}

@include media-up(s) {
  html, body {
    font-size: 20px;
  }

  .section.hero .title {
    font-size: 1.8rem;
    img {
      width: 30px;
    }
  }
}

@include media-up(sm) {
  body {
    font-size: 18px;
  }

  .title {
    font-size: 3rem;
    line-height: 4rem;
  }
  .subtitle {
    font-size: 2.5rem;
    line-height: 3rem;
  }
  .text {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
  .text--wrap {
    p {
      max-width: 50%;

      &::first-letter {
        font-size: 2rem;
      }
    }
  }

  section.hero {
    .title {
      margin-bottom: 0;
      font-size: 3rem !important;

      img {
        width: 50px !important;
      }
    }
  }

  .grid {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 2fr;

    img:first-child {
      grid-row: 1 / 3;
      grid-column: 1 / 2;
    }

    &.reverse {
      grid-template-columns: 1fr 2fr;
      img:first-child {
        grid-row: unset;
        grid-column: unset;
      }
      img:last-child {
        grid-row: 1 / 3;
        grid-column: 2 / 3;
      }
    }
  }

  td {
    &:hover .copy.icon{
      visibility: visible;
    }
  }
}

@include media-up(md) {
  .content {
    width: map-get($breakpoints, md);
    padding: spacing(3);
  }

  section.hero {
    .content {
      padding-left: 40px;
    }
    .title {
      margin-left: -40px;
    }
  }
  footer {
  }

  .button {
    padding: spacing(2) spacing(3);
    font-size: 1rem;
  }

  .cols {
    flex-direction: row;
    .col {
      min-width: 500px;
      padding: 0 0 20px 0;

      table {
        padding: spacing(2);
      }

      p {
        font-size: 1rem;
        margin-top: 199px;   
      }
    }
  }
}