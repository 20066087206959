// from https://glennmccomb.com/articles/useful-sass-scss-media-query-mixins-for-bootstrap/
//  MEDIA QUERIES
//––––––––––––––––––––––––––––––––––––––––––––––––––

// available breakpoints declared in `_vars.scss`
// $breakpoints: (
//   xs: 0px,
//   sm: 600px,
//   md: 960px,
//   lg: 1280px,
//   xl: 1920px,
// );

//
//  MEDIA UP
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include media-up(sm) {}
@mixin media-up($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (min-width: $breakpoint-value) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//
//  MEDIA DOWN
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include media-down(sm) {}
@mixin media-down($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//
//  MEDIA BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include media-between(sm, md) {}
@mixin media-between($lower, $upper) {
  // If both the lower and upper breakpoints exist in the map.
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    // Get the lower and upper breakpoints.
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    // Write the media query.
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }

    // If one or both of the breakpoints don't exist.
  } @else {
    // If lower breakpoint is invalid.
    @if (map-has-key($breakpoints, $lower) == false) {
      // Log a warning.
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }

    // If upper breakpoint is invalid.
    @if (map-has-key($breakpoints, $upper) == false) {
      // Log a warning.
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}
